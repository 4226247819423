<template>
  <div class="container">
    <div class="satMenu">
      <SatMenu active="4" />
    </div>
    <PageTitle indent>
      Analytics
      <el-select
        style="width: 60%"
        :value="drawChatType"
        @change="routerToDrawChatType"
      >
        <el-option :value="drawChatTypes.totalTestChart" label="考卷" />
        <el-option
          :value="drawChatTypes.separateUnlockAndScoresExamChart"
          label="批改考卷"
        />
        <el-option :value="drawChatTypes.testTypesChart" label="考卷種類" />
        <el-option
          :value="drawChatTypes.orderPaymentTotalChart"
          label="儲值金額"
        />
        <el-option
          :value="drawChatTypes.tCoinPaymentCountChart"
          label="Ｔ幣交易筆數"
        />
        <el-option
          :value="drawChatTypes.tCoinPaymentTotalChart"
          label="Ｔ幣交易金額"
        />
      </el-select>
    </PageTitle>
    <hr />
    <h3>查詢區間</h3>
    <div class="filters">
      <el-button
        :type="dateRangeType === dateRangeTypes.firstDay ? 'primary' : ''"
        @click="() => routerToDateRange(dateRangeTypes.firstDay)"
      >
        所有資料
      </el-button>
      <el-button
        :type="dateRangeType === dateRangeTypes.year ? 'primary' : ''"
        @click="() => routerToDateRange(dateRangeTypes.year)"
      >
        前一年資料
      </el-button>
      <el-button
        :type="dateRangeType === dateRangeTypes.halfAYear ? 'primary' : ''"
        @click="() => routerToDateRange(dateRangeTypes.halfAYear)"
      >
        前半年資料
      </el-button>
      <el-button
        :type="dateRangeType === dateRangeTypes.month ? 'primary' : ''"
        @click="() => routerToDateRange(dateRangeTypes.month)"
      >
        前一月資料
      </el-button>
      <el-button
        :type="dateRangeType === dateRangeTypes.week ? 'primary' : ''"
        @click="() => routerToDateRange(dateRangeTypes.week)"
      >
        前一週資料
      </el-button>
    </div>
    <div class="filters">
      <el-button
        :type="xAxisCaculateRangeType === xAxisCaculateRangeTypes.day ? 'primary' : ''"
        @click="() => routerToXAxisCaculateRange(xAxisCaculateRangeTypes.day)"
      >
        1 天
      </el-button>
      <el-button
        :type="xAxisCaculateRangeType === xAxisCaculateRangeTypes.week ? 'primary' : ''"
        @click="() => routerToXAxisCaculateRange(xAxisCaculateRangeTypes.week)"
      >
        1 週
      </el-button>
      <el-button
        :type="xAxisCaculateRangeType === xAxisCaculateRangeTypes.month ? 'primary' : ''"
        @click="() => routerToXAxisCaculateRange(xAxisCaculateRangeTypes.month)"
      >
        1 個月
      </el-button>
      <el-button
        :type="xAxisCaculateRangeType === xAxisCaculateRangeTypes.year ? 'primary' : ''"
        @click="() => routerToXAxisCaculateRange(xAxisCaculateRangeTypes.year)"
      >
        1 年
      </el-button>
    </div>
    <hr />
    <div class="analyticsChart" ref="analyticsChart" />
  </div>
</template>

<script>
import moment from "moment";
import echarts from "echarts";
import PageTitle from "@/components/PageTitle.vue";
import SatMenu from "@/views/components/SatMenu.vue";

export default {
  metaInfo() {
    return {
      title: "Analytics - " + this.CompanyName
    };
  },
  components: {
    PageTitle,
    SatMenu
  },
  data() {
    return {
      dateRangeType: null,
      drawChatType: null,
      xAxisCaculateRangeType: null
    };
  },
  computed: {
    drawChatTypes() {
      return {
        totalTestChart: "totalTestChart",
        separateUnlockAndScoresExamChart: "separateUnlockAndScoresExamChart",
        tCoinPaymentCountChart: "tCoinPaymentCountChart",
        tCoinPaymentTotalChart: "tCoinPaymentTotalChart",
        testTypesChart: "testTypesChart",
        orderPaymentTotalChart: "orderPaymentTotalChart"
      };
    },
    xAxisCaculateRangeTypes() {
      return {
        day: "day",
        week: "week",
        month: "month",
        year: "year"
      };
    },
    dateRangeTypes() {
      return {
        firstDay: "firstDay",
        year: "year",
        halfAYear: "halfAYear",
        month: "month",
        week: "week"
      };
    },
    firstDayDate() {
      return "2020-12-08";
    },
    lastYearDate() {
      return moment().subtract(1, "years").format("yyyy-MM-DD");
    },
    lastHalfAYearDate() {
      return moment().subtract(6, "months").format("yyyy-MM-DD");
    },
    lastMonthDate() {
      return moment().subtract(1, "months").format("yyyy-MM-DD");
    },
    lastWeekDate() {
      return moment().subtract(1, "weeks").format("yyyy-MM-DD");
    },
    analyses() {
      return this.$store.getters["analytics/analysesMappingArray"];
    }
  },
  watch: {
    drawChatType() {
      this.draw();
    },
    analyses() {
      this.draw();
    }
  },
  async created() {
    this.drawChatType = this.$route.query.drawChatType || this.drawChatTypes.totalTestChart;
    this.xAxisCaculateRangeType = this.$route.query.xAxisCaculateRangeType || this.xAxisCaculateRangeTypes.day;
    this.dateRangeType = this.$route.query.dateRangeType || this.dateRangeTypes.week;
    this.fetchAnalytics(this.dateRangeType);
  },
  methods: {
    routerToDrawChat(Condition) {
      this.$router.push({
        name: "ToeflAnalytics",
        query: {
          ...this.$route.query,
          ...Condition
        }
      });
    },
    routerToDrawChatType(drawChatType) {
      this.routerToDrawChat({ drawChatType });
    },
    routerToDateRange(dateRangeType) {
      this.routerToDrawChat({ dateRangeType });
    },
    routerToXAxisCaculateRange(xAxisCaculateRangeType) {
      this.routerToDrawChat({ xAxisCaculateRangeType });
    },
    fetchAnalytics(dateRangeType) {
      switch (dateRangeType) {
        case this.dateRangeTypes.firstDay:
          this.fetchAnalyticsFrom(this.firstDayDate);
          break;
        case this.dateRangeTypes.year:
          this.fetchAnalyticsFrom(this.lastYearDate);
          break;
        case this.dateRangeTypes.halfAYear:
          this.fetchAnalyticsFrom(this.lastHalfAYearDate);
          break;
        case this.dateRangeTypes.month:
          this.fetchAnalyticsFrom(this.lastMonthDate);
          break;
        case this.dateRangeTypes.week:
          this.fetchAnalyticsFrom(this.lastWeekDate);
          break;
        default:
      }
    },
    fetchAnalyticsFrom(
      fromDate = this.lastWeekDate,
      endDate = moment().format("yyyy-MM-DD")
    ) {
      const payload = {
        start_date: fromDate,
        end_date: endDate
      };
      switch (this.drawChatType) {
        case this.drawChatTypes.totalTestChart:
        case this.drawChatTypes.separateUnlockAndScoresExamChart:
        case this.drawChatTypes.testTypesChart:
          this.$store.dispatch("analytics/fetchAnalytics", payload);
          break;
        case this.drawChatTypes.tCoinPaymentCountChart:
        case this.drawChatTypes.tCoinPaymentTotalChart:
          this.$store.dispatch("analytics/fetchTransactionAnalytics", payload);
          break;
        case this.drawChatTypes.orderPaymentTotalChart:
          this.$store.dispatch("analytics/fetchOrderAnalytics", payload);
          break;
        default:
      }
    },
    draw() {
      this.charts = echarts.init(this.$refs["analyticsChart"]);
      let chartData = [];
      switch (this.drawChatType) {
        case this.drawChatTypes.totalTestChart:
          chartData = [
            { name: "所有考卷數", key: "total_taken_number", color: "#99cde5" },
            { name: "非空白考卷", key: "answered_take_number", color: "#fc9294" },
            { name: "已完成考卷數", key: "finished_take_number", color: "#fce59b" }
          ];
          break;
        case this.drawChatTypes.separateUnlockAndScoresExamChart:
          chartData = [
            { name: "要求批改考卷數", key: "total_request_correct_number", color: "#99cde5" },
            { name: "簡單評語批改數", key: "simple_request_correct_number", color: "#fc9294" },
            { name: "詳細評語批改數", key: "vip_request_correct_number", color: "#fce59b" },
            { name: "分數批改數", key: "score_request_correct_number", color: "#abd795" }
          ];
          break;
        case this.drawChatTypes.testTypesChart:
          chartData = [
            { name: "所有完整模考數", key: "full_mock_total_taken_number", color: "#99cde5" },
            { name: "完成完整模考數", key: "full_mock_finished_taken_number", color: "#009de6" },
            { name: "所有快速模考數", key: "quick_mock_total_taken_number", color: "#fc9294" },
            { name: "完成快速模考數", key: "quick_mock_finished_taken_number", color: "#fc0004" },
            { name: "所有閱讀考卷數", key: "reading_total_taken_number", color: "#fce59b" },
            { name: "完成閱讀考卷數", key: "reading_finished_taken_number", color: "#ffc400" },
            { name: "所有聽力考卷數", key: "listening_total_taken_number", color: "#abd795" },
            { name: "完成聽力考卷數", key: "listening_finished_taken_number", color: "#42c700" },
            { name: "所有口說考卷數", key: "speaking_total_taken_number", color: "#9077b5" },
            { name: "完成口說考卷數", key: "speaking_finished_taken_number", color: "#4700b0" },
            { name: "所有寫作考卷數", key: "writing_total_taken_number", color: "#7a645d" },
            { name: "完成寫作考卷數", key: "writing_finished_taken_number", color: "#701a00" }
          ];
          break;
        case this.drawChatTypes.tCoinPaymentCountChart:
          chartData = [
            { name: "消費在批改考卷的筆數", key: "consume_request_correct_number", color: "#99cde5" },
            { name: "總消費 T 幣筆數", key: "consume_total_number", color: "#fc9294" },
            { name: "花錢儲值 T 幣筆數", key: "deposit_currency_number", color: "#fce59b" },
            { name: "儲值 T 幣總筆數", key: "deposit_total_number", color: "#abd795" }
          ];
          break;
        case this.drawChatTypes.tCoinPaymentTotalChart:
          chartData = [
            { name: "總消費 T 幣", key: "consume_total_coin", color: "#99cde5" },
            { name: "儲值 T 幣總額", key: "deposit_total_coin", color: "#fc9294" },
            { name: "花錢儲值 T 幣總額", key: "deposit_currency_coin", color: "#fce59b" }
          ];
          break;
        case this.drawChatTypes.orderPaymentTotalChart:
          chartData = [
            { name: "儲值金額", key: "total_price", color: "#99cde5" }
          ];
          break;
        default:
      }
      const option = {
        color: chartData.map(({ color }) => color),
        legend: {
          data: chartData.map(({ name }) => name)
        },
        series: chartData.map(datum => this.getLineOfChart(datum.name, datum.key)),
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.mergeDatesByxAxisCaculateRange(this.analyses.date)
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ]
      };
      this.charts.setOption(option);
    },
    mergeDatesByxAxisCaculateRange(dates) {
      const getMergedDatesByGap = gap => {
        const mergedDates = [];
        let currentMergeDate = "";
        let currentIndex = 0;
        dates.forEach((date, index) => {
          currentIndex += 1;
          if (currentIndex === 1) {
            currentMergeDate += date;
          }

          if (currentIndex === gap || index === dates.length - 1) {
            currentMergeDate += ` ~ ${date}`;
            mergedDates.push(currentMergeDate);
            currentMergeDate = "";
            currentIndex = 0;
          }
        });
        return mergedDates;
      };
      switch (this.xAxisCaculateRangeType) {
        case this.xAxisCaculateRangeTypes.day:
          return dates;
        case this.xAxisCaculateRangeTypes.week:
          return getMergedDatesByGap(7);
        case this.xAxisCaculateRangeTypes.month:
          return getMergedDatesByGap(30);
        case this.xAxisCaculateRangeTypes.year:
          return getMergedDatesByGap(365);
        default:
          return dates;
      }
    },
    calculateDataByxAxisCaculateRange(data) {
      const getMergedDataByGap = gap => {
        const mergedData = [];
        let currentMergeDatum = 0;
        let currentIndex = 0;
        data.forEach((datum, index) => {
          currentIndex += 1;
          currentMergeDatum += Number(datum);

          if (currentIndex === gap || index === data.length - 1) {
            mergedData.push(currentMergeDatum);
            currentMergeDatum = 0;
            currentIndex = 0;
          }
        });
        return mergedData;
      };
      switch (this.xAxisCaculateRangeType) {
        case this.xAxisCaculateRangeTypes.day:
          return data;
        case this.xAxisCaculateRangeTypes.week:
          return getMergedDataByGap(7);
        case this.xAxisCaculateRangeTypes.month:
          return getMergedDataByGap(30);
        case this.xAxisCaculateRangeTypes.year:
          return getMergedDataByGap(365);
        default:
          return data;
      }
    },
    getLineOfChart(dataName, dataKey) {
      return {
        name: dataName,
        type: "line",
        areaStyle: {},
        emphasis: {
          focus: "series"
        },
        data: this.calculateDataByxAxisCaculateRange(this.analyses[dataKey])
      };
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  .satMenu {
    margin-bottom: 15px;
  }

  .analyticsChart {
    width: 100%;
    height: 600px;
  }
}

h3 {
  margin-top: 24px;
}

.filters {
  margin: 12px 0px;
}
</style>
